import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faExclamationCircle,
  faCheckCircle,
  faComments,
  faPlus,
  faArrowUp,
  faCommentAlt,
  // faDotCircle,
  faSearch,
  faEye,
  faEllipsisV,
  faChevronDown,
  faChevronUp,
  faHeart,
  faCheckSquare,
  faTrashAlt,
  faMapMarkerAlt,
  faUserCircle,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import {
  faDotCircle,
  faCheckCircle as farCheckCircle,
  faHeart as farHeart,
  faCheckSquare as farCheckSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faHome,
  faExclamationCircle,
  faCheckCircle,
  farCheckCircle,
  faComments,
  faPlus,
  faArrowUp,
  faCommentAlt,
  faDotCircle,
  faSearch,
  faEye,
  faEllipsisV,
  faChevronDown,
  faChevronUp,
  faHeart,
  farHeart,
  faCheckSquare,
  farCheckSquare,
  faTrashAlt,
  faMapMarkerAlt,
  faUserCircle,
  faUsers,
);
